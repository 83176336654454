import axios from '@/utils/request'


// 获取消息列表teacherList
export const talentEchelonUserList = (data) => {
    return axios.request({
        url: '/api/talentEchelonUser/list',
        data,
        method: 'get'
    })
}

// 获取消息列表teacherList
export const talentEchelonUserListNew = (data) => {
    return axios.request({
        url: '/api/talentEchelonUser/employeeData',
        data,
        method: 'get'
    })
}

// 获取消息列表queryTeacherList
export const queryTalentEchelonUser = (data) => {
    return axios.request({
        url: '/api/talentEchelonUser/queryById',
        data,
        method: 'get'
    })
}
// 获取企业文化
export const userSeasonAppraiseList = (data) => {
    return axios.request({
        url: '/api/userSeasonAppraise/list',
        data,
        method: 'get'
    })
}
// 历史人才评级/api/userSeasonAppraise/seasonAppraiselist
export const getUserSeasonAppraiseList = (data) => {
    return axios.request({
        url: '/api/userSeasonAppraise/seasonAppraiselist',
        data,
        method: 'get'
    })
}
export const addUserSeasonAbility = (data) => {
    return axios.request({
        url: '/api/userSeasonAppraise/submit',
        data,
        method: 'put'
    })
}
//绩效
export const addUserSeasonAppraise = (data) => {
    return axios.request({
        url: '/api/userSeasonAppraise/submitAppraise',
        data,
        method: 'put'
    })
}
//绩效
export const addUserSeasonAppraisesub = (data) => {
    return axios.request({
        url: '/api/userSeasonAppraise/submitAbility',
        data,
        method: 'put'
    })
}
//用户奖惩
export const userRewardPunishmentList = (data) => {
    return axios.request({
        url: '/api/userRewardPunishment/list',
        data,
        method: 'get'
    })
}
//人才评级获取枚举
export const configList = (data) => {
    return axios.request({
        url: '/api/config/data',
        data,
        method: 'get'
    })
}
//用户奖惩
export const performList = (data) => {
    return axios.request({
        url: '/api/userSeasonAppraise/queryByYearAppraiseId',
        data,
        method: 'get'
    })
}
//用户奖惩
export const abilityList = (data) => {
    return axios.request({
        url: '/api/userSeasonAppraise/queryBySeasonAppraiseId',
        data,
        method: 'get'
    })
}