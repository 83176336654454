<template>
  <div class="talents_box">
    <loading :loading="timesSpining"></loading>
    <div class="talents">
      <div class="talents_condition">
        <div class="talents_condition_inp">
          <div class="talents_condition_inpName">姓名：</div>
          <a-input
            allowClear
            v-model="formInline.realName"
            style="width: 272px"
            placeholder="请输入"
          />
        </div>
        <a-button
          type="primary"
          shape="round"
          html-type="submit"
          @click="searchQuery"
          class="talents_condition_btnCheck"
          >查询</a-button
        >
        <a-button
          shape="round"
          style="background: #b1b1b1"
          html-type="submit"
          @click="reset"
          class="talents_condition_btnReset"
          >重置</a-button
        >
      </div>
      <div class="talents_table">
        <a-table
          :loading="rankingData.rankingLoading"
          :columns="rankingData.columns"
          :data-source="rankingData.data"
          size="middle"
          :pagination="false"
          :rowKey="(record) => record.id"
        >
          <template slot="index" slot-scope="record, index, indent">

            <span class="weight__border">{{
                (rankingData.pagination.current - 1) *
                rankingData.pagination.pageSize +
                Number(indent) +
                1
              }}</span>
          </template>
          <template slot="action" slot-scope="text, record, index">
            <a @click="toDetail(record, index)">查看详情</a>
          </template>
        </a-table>
        <div class="ranking_page_box" v-if="rankingData.data.length > 0">
          <div
            :class="[
              'ranking_page_first',
              currentNumFirst ? '' : 'noCursor',
              rankingData.rankingLoading ? 'btn_disabled' : '',
            ]"
            @click="firstBtn"
          >
            首页
          </div>
          <div class="ranking_page_center">
            <a-pagination
              v-model="rankingData.pagination.current"
              :total="rankingData.pagination.total"
              :pageSize="rankingData.pagination.pageSize"
              @change="changeRankPage"
              :disabled="rankingData.rankingLoading"
            />
          </div>
          <div
            :class="[
              'ranking_page_end',
              currentNumEnd ? '' : 'noCursor',
              rankingData.rankingLoading ? 'btn_disabled' : '',
            ]"
            @click="endBtn"
          >
            尾页
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { talentEchelonUserListNew } from "@/api/talents.js";
import loading from "@/components/loading";
const columns = [
  {
    title: "序号",
    dataIndex: "number",
    align: "center",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "姓名",
    dataIndex: "realname",
    align: "center",
  },
  // {
  //   title: "上级部门",
  //   dataIndex: "departNameAbbr",
  //   align: "center"
  // },
  {
    title: "学习进度",
    dataIndex: "studyRate",
    align: "center",
  },
  {
    title: "未完成",
    dataIndex: "unFinishCount",
    align: "center",
  },
  {
    title: "已完成",
    dataIndex: "finishiCount",
    align: "center",
  },
  {
    title: "最后学习时间",
    dataIndex: "latestStudyTime",
    align: "center",
  },
  {
    title: "证书情况",
    dataIndex: "certificateCount",
    align: "center",
  },
  // {
  //   title: "操作",
  //   dataIndex: "action",
  //   align: "center",
  //   scopedSlots: { customRender: "action" }
  // }
];

const data = [];
export default {
  data() {
    return {
      timesSpining: false,
      formInline: {},
      data,
      tableLoading: false,
      date: null,
      departTree: [],
      taskData: [],
      add: [{ number: 1 }],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      rankingData: {
        columns: columns,
        data: [],
        rankingLoading: false,
        userData: {},
        pagination: {
          total: 0,
          current: 1,
          pageSize: 10,
        },
      },
    };
  },
  components: { loading },
  computed: {
    enumMap() {
      return this.$store.state.app.enumMap;
    },
    currentNumFirst() {
      if (this.rankingData.pagination.current !== 1) {
        return true;
      } else {
        return false;
      }
    },
    currentNumEnd() {
      let num =
        this.rankingData.pagination.total /
          this.rankingData.pagination.pageSize -
        this.rankingData.pagination.current;
      if (num > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    this.getData();
    // this.getDeptList();
  },
  methods: {
    getData() {
      this.timesSpining = true;
      this.rankingData.rankingLoading = true;
      let id = JSON.parse(localStorage.getItem("USER_ID")).value;
      const params = {
        userId: id,
        pageNo: this.rankingData.pagination.current,
        pageSize: this.rankingData.pagination.pageSize
      };
      Object.assign(params, this.formInline);
      talentEchelonUserListNew(params)
        .then((res) => {
          console.log("报表的请求");
          if (res.code === 200) {
            this.rankingData.data = res.result.records;
            // taskName
            this.taskData = this.rankingData.data.map((item) => {
              const i = {
                key: item.taskPathIds,
                value: item.taskPathIds,
              };
              return i;
            });
            this.rankingData.pagination.total = res.result.total;
          } else {
            this.$message.error(res.message);
          }
        })
        .finally(() => {
          this.rankingData.rankingLoading = false;
          this.timesSpining = false;
        });
    },
    searchQuery() {
      this.getData();
    },
    reset() {
      this.formInline = {};
      this.rankingData.pagination.current = 1;
      this.getData()
    },
    changeRankPage(page, pageSize) {
      // 点击上下页或者其他页的时候
      this.rankingData.pagination.current = page;
      this.rankingData.pagination.pageSize = pageSize;
      this.getData();
    },
    firstBtn() {
      if (this.rankingData.pagination.current == 1) {
        return;
      }
      this.rankingData.pagination.current = 1;
      this.getData();
    },
    endBtn() {
      if (
        Math.ceil(
          this.rankingData.pagination.total /
            this.rankingData.pagination.pageSize
        ) == this.rankingData.pagination.current
      ) {
        return;
      }
      this.rankingData.pagination.current = Math.ceil(
        this.rankingData.pagination.total / this.rankingData.pagination.pageSize
      );
      this.getData();
    },
    toDetail(record, index) {
      console.log(record, index);
      this.$router.push({
        name: "talentsDetails",
        query: { userId: record.userId, id: record.id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.talents_box {
  width: 1200px;
  margin: 48px auto 192px;
  background: #ffffff;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  .talents_condition {
    padding: 24px 48px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .talents_condition_inp {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .talents_condition_inpName {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #989898;
        line-height: 50px;
      }
    }
    .talents_condition_btnCheck {
      width: 89px;
      height: 41px;
      background: #5db4ff;
      line-height: 41px;
      text-align: center;
      color: #ffffff;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      border-color: #5db4ff;
      margin-left: 23px;
    }
    .talents_condition_btnReset {
      width: 89px;
      height: 41px;
      background: #f5f5f5;
      line-height: 41px;
      text-align: center;
      color: #ffffff;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      margin-left: 14px;
      border-color: #f5f5f5;
    }
  }
  .talents_table {
    width: 1152px;
    margin: 0 auto;
    padding-bottom: 48px;
    .ranking_page_box {
      margin: 49px auto 18px;
      display: flex;
      justify-content: center;
      .ranking_page_first {
        width: 50px;
        height: 31px;
        border: 1px solid #bfbfbf;
        text-align: center;
        line-height: 29px;
        font-size: 16px;
        font-weight: 400;
        color: #878787;
        border-radius: 31px;
        margin-right: 9px;
        cursor: pointer;
      }
      .ranking_page_end {
        width: 50px;
        height: 31px;
        border: 1px solid #bfbfbf;
        text-align: center;
        line-height: 29px;
        font-size: 16px;
        font-weight: 400;
        color: #878787;
        border-radius: 31px;
        margin-left: 9px;
        cursor: pointer;
      }
      .ranking_page_first:hover,
      .ranking_page_end:hover {
        border: 1px solid #5db4ff;
        color: #ffffff;
        background: #5db4ff;
      }
      .noCursor {
        cursor: not-allowed !important;
      }
      .noCursor:hover {
        border: 1px solid #bfbfbf;
        color: #878787;
        background: #ffffff;
      }
      .btn_disabled {
        background: #f5f5f5;
        border-color: #d9d9d9;
        color: #878787;
      }
    }
  }
}

.text__center_btn {
  border-radius: 25px;
  width: 94px;
  height: 38px;
  line-height: 38px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}

::v-deep .ant-input-affix-wrapper {
  height: 41px;
  border: 1px solid #e3e3e3;
  border-radius: 0;
}
::v-deep .ant-input-affix-wrapper .ant-input {
  height: 100%;
  border: none;
  background: #f5f5f5;
}
::v-deep .ant-input:focus {
  border: none;
}

::v-deep .ant-table-thead > tr > th {
  border-bottom: none;
  color: #989898;
  padding: 0 16px;
}
::v-deep .ant-table-thead {
  height: 70px;
  font-size: 24px;
  font-weight: bold;
  color: #989898;
}
::v-deep .ant-table-tbody > tr > td {
  color: #989898;
  border-bottom: 1px solid #f5f5f5;
  position: relative;
  padding: 0 16px;
}
::v-deep .ant-table-tbody {
  min-height: 700px;
}
::v-deep .ant-table-tbody > tr {
  height: 70px;
}
::v-deep .ant-table-row-hover,
.ant-table-row-hover > td {
  background-color: #def0ff;
}
::v-deep .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td,
.ant-table-row-hover,
.ant-table-row-hover > td {
  background-color: #def0ff !important;
}

::v-deep .ant-pagination-prev,
.ant-pagination-next {
  width: 31px;
  height: 31px;
  margin-right: 10px;
}
::v-deep .ant-pagination-prev .ant-pagination-item-link {
  border-radius: 50%;
  border: 1px solid #bfbfbf;
  color: #878787;
  font-size: 13px;
}
::v-deep .ant-pagination-prev:hover .ant-pagination-item-link {
  border-radius: 50%;
  border: 1px solid #5db4ff;
  color: #ffffff;
  background: #5db4ff;
}
::v-deep .ant-pagination-next .ant-pagination-item-link {
  border-radius: 50%;
  border: 1px solid #bfbfbf;
  color: #878787;
  font-size: 13px;
}
::v-deep .ant-pagination-next:hover .ant-pagination-item-link {
  border-radius: 50%;
  border: 1px solid #5db4ff;
  color: #ffffff;
  font-size: 13px;
  background: #5db4ff;
}
::v-deep .ant-pagination-item {
  background-color: #fff;
  border: 1px solid #bfbfbf;
  border-radius: 50%;
  width: 31px;
  height: 31px;
  line-height: 29px;
}
::v-deep .ant-pagination-item a {
  padding: 0;
}
::v-deep .ant-pagination-item-active {
  border: 1px solid #5db4ff;
}
::v-deep .ant-pagination-item-active a {
  color: #ffffff;
  background: #5db4ff;
  border-radius: 50%;
}
::v-deep .ant-pagination-item:hover {
  border: 1px solid #5db4ff;
}
::v-deep .ant-pagination-item:hover a {
  color: #ffffff;
  background: #5db4ff;
  border-radius: 50%;
}
::v-deep
  .ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-link-icon {
  color: #5db4ff;
}
::v-deep .ant-pagination-disabled:hover .ant-pagination-item-link {
  border-radius: 50%;
  border: 1px solid #bfbfbf !important;
  color: #878787 !important;
  background: #ffffff !important;
}
</style>
